// import AOS from 'aos';
import AOS from 'aos';
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade, Thumbs } from 'swiper/modules';
// import 'photoswipe/style.css';
import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { create, registerPlugin } from 'filepond';
import * as FilePond from 'filepond';
import 'filepond/dist/filepond.css';

Swiper.use([Navigation, Pagination, Autoplay, EffectFade, Thumbs]);
window.Swiper = Swiper;

// JavaScript
$(document).ready( function () {
  AOS.init();

  // header
  $('.menu-link').click(function(e) {
    $(this).parent().toggleClass('active');
  });
  $('.dropdown-link.dropdown').click(function(e) {
      $(this).toggleClass('active');
  });
  $('.header-mobile-menu').on('click', function () {
    $(this).toggleClass('active');
    $('#headerMenu').toggleClass('active');
  });
  $('.header-lang-group').on('click', function () {
    $(this).toggleClass('active');
    $('#langSwitch').toggleClass('active');
  });

  // transparent header
  function transparentHeader() {
    if ($(window).scrollTop() > 100) {
      $('.header').addClass("min-header");
    } else {
      $('.header').removeClass("min-header");
    }
  }
  transparentHeader();
  $(window).on('scroll', function () {
    transparentHeader();
  });

  // index
  var indexBanner = new Swiper('#indexBanner', {
    effect: 'fade',
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    parallax: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // about
  var aboutMilestone = new Swiper('#aboutMilestone', {
    slidesPerView: 2,
    speed: 1000,
    grabCursor: true,
    breakpoints: {
      800: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 4,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  // tab
  $(function(){
    var tab = $('.tabs-group .tabs-btn a.tab');
    $(tab.eq(0).addClass('active').attr('href')).addClass('active').siblings('.tab-content').hide();

    tab.click(function(){
        event.preventDefault();
        $($(this).attr('href')).addClass('active').slideDown().siblings('.tab-content').hide();
        $(this).addClass('active').siblings('.active').removeClass('active');
    });
  });

  // 圖片zoom in
  var lightbox = new PhotoSwipeLightbox({
    gallery: '#custom_lightbox',
    children: 'a',
    mainClass: 'lightbox-custom',
    showHideAnimationType: 'fade',
    pswpModule: () => import('photoswipe'),
    counter: false
  });
  lightbox.init();

  //scroll top
  // $('#backTop').click(function(){
  //   $("html,body").animate({scrollTop: 0}, 600);
  // });

  // 實習媒合檔案上傳
  const inputElement = document.querySelector('.filepond');
  FilePond.create((inputElement),
    {
      credits: false,
      labelIdle: `點擊上傳，或拖曳檔案至框內`,
      acceptedFileTypes: ['image/jpg', 'image/jpeg'],
    }
  );

});

const Swal_lg = Swal.mixin({
  width: 800,
  showCloseButton: true,
  showConfirmButton: false,
  customClass: {
    container: 'mobile-full',
  },
});
window.Swal_lg = Swal_lg;
