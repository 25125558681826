// import Cookies from 'js-cookie';
import 'core-js';
import 'public/main'
import 'public/style.scss'

import Rails from "@rails/ujs"
Rails.start();

window.jQuery = $;
window.$ = $;
// window.Cookies = Cookies;

